import React from "react"

const Preload = ({}) => (
  <div id="preloader">
    <div className="alignment">
      <div className="v-align center-middle">
        <div className="heart-animation">
          <i className="de-icon-heart"></i>
        </div>

        <div className="heart-animation-reverse">
          <i className="de-icon-heart"></i>
        </div>
      </div>
    </div>
  </div>
)

Preload.propTypes = {}

Preload.defaultProps = {}

export default Preload
