import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const GalleryImg = props => {
  return (
    <div className="masonry-item">
      <div className="photo-item frame-border animation fadeIn">
        <img
          src={props.src}
          alt=""
          className="hover-animation image-zoom-rotate"
        />

        <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

        <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
          <div className="alignment">
            <div className="v-align center-middle">
              <a
                href={props.src}
                className="magnific-zoom-gallery"
                title="Attachment-1"
              >
                <div className="de-icon circle light medium-size inline">
                  <i className="de-icon-zoom-in"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GalleryImg
