import React from "react"

const Couple = ({}) => (
  <section id="couple">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="photo-item frame-border animation fadeInLeft">
            <img
              src="/images/evi.jpg"
              alt=""
              className="hover-animation image-zoom-in"
            />

            <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

            <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
              <div className="alignment">
                <div className="v-align center-middle">
                  <a href="/images/evi.jpg" className="magnific-zoom">
                    <div className="de-icon circle light medium-size inline">
                      <i className="de-icon-zoom-in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="his-her-name">
            <h2 className="text-center">
              Evi
              <span className="last-name">-&nbsp;Katsoulieri&nbsp;-</span>
            </h2>
            <div className="heart-wrapper">
              <div className="de-icon circle medium-size custom-heart">
                <i className="de-icon-heart"></i>
              </div>
            </div>
          </div>

          <p className="blurb animation fadeInLeft text-center"></p>
        </div>

        <div className="col-md-6">
          <div className="photo-item frame-border animation fadeInRight">
            <img
              src="/images/bill.jpg"
              alt=""
              className="hover-animation image-zoom-in"
            />

            <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

            <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
              <div className="alignment">
                <div className="v-align center-middle">
                  <a
                    href="https://placehold.it/700x467"
                    className="magnific-zoom"
                  >
                    <div className="de-icon circle light medium-size inline">
                      <i className="de-icon-zoom-in"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="his-her-name">
            <h2 className="text-center">
              Bill
              <span className="last-name">-&nbsp;Stavroulakis&nbsp;-</span>
            </h2>
          </div>

          <p className="blurb animation fadeInRight text-center"></p>
        </div>
      </div>
    </div>
  </section>
)

Couple.propTypes = {}

Couple.defaultProps = {}

export default Couple
