import React from "react"

const WhereWhen = ({}) => (
  <section id="where-when">
    <div className="container">
      <div className="row">
        <div className="col-md-offset-2 col-md-8 text-center">
          <div className="section-title animation fadeInUp">
            <h2>
              Where <span className="and">&amp;</span> When
            </h2>

            <p>
              Information about the location of the venue and the time of the
              wedding. The ceremony and the reception will take place at the
              same location.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center">
          <div className="banner-text dark full animation fadeIn">
            <div className="row">
              <div className="col-md-4">
                <h3>AUGUST / 27TH / 2022</h3>
              </div>
              <div className="col-md-4">
                <div className="border-column">
                  <h3>7:30 PM</h3>
                </div>
              </div>
              <div className="col-md-4">
                <h3>KTIMA ARTEMIS</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 animation fadeIn">
          <div
            className="de-icon circle small-size inline"
            style={{ backgroundColor: "#f0394d" }}
          >
            <i className="de-icon-bell-alt"></i>
          </div>
          <h3 className="inline">CEREMONY</h3>
          <p>
            The ceremony will take place at{" "}
            <a
              href="https://www.ktimaartemis.gr/ekswterikos-xwros/"
              target="_blank"
            >
              Ktima Artemis (Κτήμα Άρτεμις)
            </a>
            <br />
            <br />
            <b>Site:&nbsp;</b>
            <a
              href="https://www.ktimaartemis.gr/ekswterikos-xwros/"
              target="_blank"
            >
              https://www.ktimaartemis.gr/
            </a>
            <br />
            <br />
            <b>Address:</b> <br />
            Sfittou & Parthenonos, <br />
            parodos Panathineon <br />
            no.2 Lakiza, Koropi 194 00, Greece <br />
            <br />
            <b>Διεύθυνση:</b> <br />
            Σφηττού & Παρθενώνος, <br />
            πάροδος Παναθηναίων αρ.2 <br />
            Θέση Λάκιζα, Κορωπί, 194 00
            <br />
            <br />
            <b>Date:</b> Aug 27, 2022 <br />
            <b>Time:</b> 7:30 pm
          </p>

          <div
            className="de-icon circle small-size inline"
            style={{ backgroundColor: "#f6700e" }}
          >
            <i className="de-icon-food"></i>
          </div>
          <h3 className="inline">RECEPTION</h3>
          <p>
            The reception will take place at Ktima Artemis right after the
            ceremony.
          </p>

          <div
            className="de-icon circle small-size inline"
            style={{ backgroundColor: "#0d9a48" }}
          >
            <i className="de-icon-home-1"></i>
          </div>
          <h3 className="inline">Transportation</h3>
          <p>
            Transportation to and from the venue is best done by a taxi or by
            car. Athens, is about 35 minutes from Ktima Artemis.
          </p>
        </div>

        <div className="col-md-6 animation fadeIn">
          <div
            id="map-canvas"
            style={{ border: "15px solid #fff", marginTop: "20px" }}
          ></div>
        </div>
      </div>
    </div>
  </section>
)

WhereWhen.propTypes = {}

WhereWhen.defaultProps = {}

export default WhereWhen
