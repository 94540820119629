/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Preload from "../components/preload"
import Slider from "../components/slider"
import Couple from "../pages/couple"
import Gallery from "../pages/gallery"
import GiftRegistry from "../pages/gift-registry"
import OurStory from "../pages/our-story"
import PhotoDivider from "../pages/photo-divider"
import WhereWhen from "../pages/where-when"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Preload />

      <Slider
        mainMedia="/images/header.jpg"
        bannerDate="AUGUST / 27TH / 2022"
        spouseA="E"
        spouseB="B"
      />

      <section id="content">
        <Couple />

        <PhotoDivider id="photo-divider" mediaURL="/images/header2.jpg" />

        <OurStory />

        <PhotoDivider id="photo-divider-2" mediaURL="/images/header6.jpg" />

        <WhereWhen />

        <section id="attending">
          <div
            className="image-divider auto-height"
            style={{ backgroundImage: "url(/images/header5.jpg)" }}
            data-stellar-background-ratio="0.3"
          >
            <div className="divider-overlay"></div>

            <div className="container">
              <div className="row">
                <div className="col-md-offset-2 col-md-8 text-center">
                  <div className="banner-text withlove large light animation fadeInUp">
                    <h2 className="h1">ARE YOU ATTENDING?</h2>
                  </div>

                  <div className="text-center animation fadeInUp">
                    <p>
                      At this point, we would like to kindly ask you to Save the
                      Date and fill the form below with your mailing addresses
                      and contact information where we can send you the wedding
                      invitation.
                    </p>

                    <p>
                      We will kindly ask you to confirm your attendance around 2
                      months before the wedding.{" "}
                    </p>

                    <p>Thank you!</p>
                  </div>

                  <div className="text-center">
                    <a
                      href="#rsvp-section"
                      className="smooth-scroll de-button outline medium animation fadeInUp"
                    >
                      RSVP
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="rsvp-section">
          <div className="container">
            <div className="row" style={{ textAlign: "center" }}>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfzH8JZIyX__DyTbayGdbloSjCRR5_JJivqYs2f37kWXsRGrw/viewform?embedded=true"
                width="640"
                height="1650"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </section>

        <Gallery id="our-moments" />

        <GiftRegistry id="registry" />
      </section>

      <footer>
        <div
          className="image-divider fixed-height"
          style={{ backgroundImage: "url(/images/header4.jpg)" }}
          data-stellar-background-ratio="0.5"
        >
          <div className="divider-overlay"></div>

          <div className="alignment">
            <div className="v-align center-middle">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="animation fadeInUp">
                      <div id="thank-you">
                        <div id="thank">Thank</div>
                        <div id="you">You</div>
                      </div>
                    </div>

                    <div className="heart-divider animation delay1 fadeInUp">
                      <span className="white-line"></span>
                      <i className="de-icon-heart pink-heart"></i>
                      <i className="de-icon-heart white-heart"></i>
                      <span className="white-line"></span>
                    </div>

                    <div
                      id="footer-couple-name"
                      className="animation delay1 fadeInUp"
                    >
                      Evi & Bill
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout
