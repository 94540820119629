import React from "react"
import GalleryImg from "../components/galleryImg"

const Gallery = ({}) => (
  <section
    id="our-moments"
    style={{
      backgroundColor: "#ffffff",
      paddingTop: "40px",
      paddingBottom: "40px",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-offset-2 col-md-8 text-center">
          <div className="section-title animation fadeInUp">
            <h2>
              OUR <img src="images/heart-icon.png" alt="" /> MOMENTS
            </h2>
          </div>
        </div>
      </div>

      <div className="row masonry">
        <GalleryImg src="/gallery/3.jpeg" />
        <GalleryImg src="/gallery/1.jpeg" />
        <GalleryImg src="/gallery/9.jpeg" />
        <GalleryImg src="/gallery/8.jpeg" />
        <GalleryImg src="/gallery/2.jpeg" />
        <GalleryImg src="/gallery/7.jpeg" />
        <GalleryImg src="/gallery/6.jpeg" />
        <GalleryImg src="/gallery/5.jpeg" />
        <GalleryImg src="/gallery/4.jpeg" />
      </div>
    </div>
  </section>
)

Gallery.propTypes = {}

Gallery.defaultProps = {}

export default Gallery
