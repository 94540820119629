import PropTypes from "prop-types"
import React from "react"

const Slider = ({ mainMedia, bannerDate, spouseA, spouseB }) => (
  <section id="main-slider" className="flexslider fullscreen">
    <div className="slide-title-outter-wrapper">
      <div className="slide-title-inner-wrapper">
        <div className="slide-title align-middle">
          <div className="container">
            <div className="row">
              <div
                id="main-slider-text"
                className="col-md-offset-3 col-md-6 animation delay1 fadeInUp"
              >
                <div id="invited">Save the date</div>
                <div className="banner-text withlove medium light">
                  <h1>WE ARE GETTING MARRIED!</h1>
                  <h2>We can't wait to share our special day with you.</h2>
                </div>
                <div id="banner-date">- {bannerDate} -</div>
                <div>Athens, Greece</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ul className="slides">
      <li>
        <div
          data-stellar-ratio="0.5"
          className="slide-image"
          style={{
            backgroundImage: `url(${mainMedia})`,
            backgroundPosition: "top center",
          }}
        ></div>

        <div className="slide-overlay" style={{ opacity: 0 }}></div>
      </li>
    </ul>

    <header id="nav-header">
      <nav
        id="nav-bar"
        className="bottom-bar outside fluid-width block-color nav-center sticky-nav animation fadeIn"
      >
        <div id="nav-wrapper">
          <div className="logo-wrapper">
            <a href="index.html">
              <div className="css-logo rounded">
                <div className="css-logo-text">
                  <strong>{spouseA}</strong>
                  <i className="de-icon-heart-1"></i>
                  <strong>{spouseB}</strong>
                </div>
              </div>
            </a>
          </div>

          <div id="mobile-nav">
            <i className="de-icon-menu"></i>
          </div>

          <ul
            id="nav-menu"
            className="nav-smooth-scroll"
            style={{ width: "1000px", margin: "0 auto", paddingLeft: "10px" }}
          >
            <li className="first-child">
              <a href="#main-slider">HOME</a>
            </li>
            <li>
              <a href="#our-story">STORY</a>
            </li>
            <li>
              <a href="#where-when">WHERE &amp; WHEN</a>
            </li>
            <li>
              <a href="#rsvp-section">RSVP</a>
            </li>
            <li className="first-child split-margin">
              <a href="#our-moments">OUR MOMENTS</a>
            </li>
            <li>
              <a href="/registry">GIFT REGISTRY</a>
            </li>
            <li>
              <a
                href="https://add.eventable.com/events/61f74167fb006d1292e3e119/61f7416808f25b219e0b4db8"
                class="eventable-link"
                target="_blank"
                data-key="61f74167fb006d1292e3e119"
                data-event="61f7416808f25b219e0b4db8"
                data-style="on"
              >
                ADD TO CALENDAR
              </a>
            </li>
          </ul>

          <div className="clearboth"></div>
        </div>
      </nav>
    </header>
  </section>
)

Slider.propTypes = {
  mainMedia: PropTypes.string,
  bannerDate: PropTypes.string,
  spouseA: PropTypes.string,
  spouseB: PropTypes.string,
}

Slider.defaultProps = {
  mainMedia: ``,
  bannerDate: ``,
  spouseA: ``,
  spouseB: ``,
}

export default Slider
